import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { login, currentAccount, logout } from 'services/user-jwt'
import actions from './actions'

// login dispatcher
export function* LOGIN({ payload }) {
  console.log('user sagas LOGIN')
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(login, email, password)
  console.log('user sagas LOGIN success', success)
  if (success) {
    notification.success({
      message: 'Logged In',
      description: '登入成功!',
    })
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
  } else {
    notification.success({
      message: 'Logged In',
      description: '登入失敗，請重試!',
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

// get current account dispatcher
export function* LOAD_CURRENT_ACCOUNT() {
  console.log('user sagas LOAD_CURRENT_ACCOUNT')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(currentAccount)
  console.log('user sagas LOAD_CURRENT_ACCOUNT success', response)
  if (response) {
    const { userid, email, photoURL: avatar, role, firstName, lastName, token, empId } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: userid,
        name: `${firstName} ${lastName}`,
        email,
        avatar,
        role,
        authorized: true,
        token,
        empId,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

// logout dispatcher
export function* LOGOUT() {
  console.log('user sagas LOGOUT')
  yield call(logout)
  console.log('user sagas LOGOUT success')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      empId: '',
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
