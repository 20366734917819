import React from 'react'
import styles from './style.module.scss'

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.inner}>
      <div className="row">
        <div className="col-lg-9" />
      </div>
      <div className={styles.bottom}>
        <div className="row">
          <div className="col-sm-6" />
          <div className="col-sm-6">
            <div className={styles.copyright}>
              <img src="resources/images/logo.png" rel="noopener noreferrer" alt="" />
              <span>&copy; 2024 Funcode. All rights reserved. v1.5.4</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
