export function getLeftMenuDataAdmin() {
  // 左選單
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'icmn icmn-home',
      url: '/dashboard',
    },
    {
      divider: true,
    },
    {
      title: '供應商管理',
      key: 'vends',
      icon: 'icmn icmn-menu',
      url: '/vends',
    },
    {
      title: '客戶管理',
      key: 'custs',
      icon: 'icmn icmn-menu',
      url: '/custs',
    },
    {
      divider: true,
    },
    {
      title: '物料管理',
      key: 'mats',
      icon: 'icmn icmn-menu',
      children: [
        {
          title: '全部',
          key: 'matsAll',
          url: '/mats/all',
        },
        {
          title: '成品',
          key: 'matsFgi',
          url: '/mats/fgi',
        },
        {
          title: '成品與配件相關',
          key: 'matsFgiAccs',
          url: '/mats/fgiAndAccs',
        },
      ],
    },
    {
      title: '庫存清單',
      key: 'stocklists',
      icon: 'icmn icmn-menu',
      children: [
        {
          title: '所有清單',
          key: 'stocklistsAll',
          url: '/stocklists',
        },
        {
          title: '已建立清單',
          key: 'stocklistsOwn',
          url: '/stocklists/own',
        },
        {
          title: '與我共用',
          key: 'stocklistsShared',
          url: '/stocklists/shared',
        },
      ],
    },
    {
      title: '庫存異動管理',
      key: 'docs-parents',
      icon: 'icmn icmn-menu',
      children: [
        {
          title: '總覽',
          key: 'docs',
          url: '/docs',
        },
        {
          title: '明細(近200筆)',
          key: 'docsLast',
          url: '/docs/last',
        },
        {
          title: '入庫',
          key: 'docsReceive',
          url: '/docs/receive',
        },
        {
          title: '出庫',
          key: 'docsIssue',
          url: '/docs/issue',
        },
        {
          title: '移轉',
          key: 'docsTransfer',
          url: '/docs/transfer',
        },
      ],
    },
    {
      title: '模具管理',
      key: 'molds',
      icon: 'icmn icmn-menu',
      url: '/tools/molds',
    },
    {
      title: '治具管理',
      key: 'jgs',
      icon: 'icmn icmn-menu',
      url: '/tools/jgs',
    },
    {
      divider: true,
    },
    {
      title: '採購管理',
      key: 'pos',
      icon: 'icmn icmn-menu',
      children: [
        {
          title: '採購管理',
          key: 'pos',
          url: '/pos',
        },
        {
          title: '明細',
          key: 'posItems',
          url: '/posItems',
        },
        {
          title: '採購請款管理',
          key: 'poInvcs',
          url: '/poInvcs',
        },
      ],
    },
    {
      divider: true,
    },
    {
      title: '訂單管理',
      key: 'sos',
      icon: 'icmn icmn-menu',
      children: [
        {
          title: '訂單管理(所有)',
          key: 'sos',
          url: '/sos',
        },
        {
          title: '訂單管理(啟用)',
          key: 'sosOpenOrders',
          url: '/sos/openOrders',
        },
        {
          title: '明細(所有)',
          key: 'sosItems',
          url: '/sosItems',
        },
        {
          title: '明細(啟用)',
          key: 'sosItemsOpenOrders',
          url: '/sosItems/openOrders',
        },
      ],
    },
    {
      title: '工單管理',
      key: 'wos',
      icon: 'icmn icmn-menu',
      children: [
        {
          title: '工單管理(所有)',
          key: 'wos',
          url: '/wos',
        },
        {
          title: '工單管理(啟用)',
          key: 'wosOpenWos',
          url: '/wos/openWos',
        },
      ],
    },
    {
      title: '出貨管理',
      key: 'delivs',
      icon: 'icmn icmn-menu',
      children: [
        {
          title: '出貨管理',
          key: 'delivs',
          url: '/delivs',
        },
        {
          title: '明細(近3個月)',
          key: 'delivsLast',
          url: '/delivs/last',
        },
      ],
    },
    {
      title: '請款管理',
      key: 'invcs',
      icon: 'icmn icmn-menu',
      url: '/invcs',
    },
    {
      divider: true,
    },
    {
      title: '報表管理',
      key: 'reports',
      icon: 'icmn icmn-menu',
      children: [
        {
          title: '進度管理KPI查詢',
          key: 'woProcKpi',
          url: '/reports/woProcKpi',
        },
      ],
    },
    {
      divider: true,
    },
    {
      title: '帳號管理',
      key: 'admins',
      icon: 'icmn icmn-users',
      url: '/admins',
    },
    {
      title: '參數管理',
      key: 'params',
      icon: 'icmn icmn-menu',
      url: '/params',
    },
  ]
}
export function getTopMenuDataAdmin() {
  // 上選單
  return []
}
